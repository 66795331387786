var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goods" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.data } },
        [
          _c("div", { staticClass: "module-title" }, [_vm._v("商品管理")]),
          _c("div", { staticClass: "selectGoods x-f" }, [
            _c(
              "div",
              {
                staticClass: "select-btn",
                on: {
                  click: function ($event) {
                    _vm.showGoodsDia = true
                  },
                },
              },
              [
                _vm._v(" 选择商品 "),
                _c("span", { staticClass: "select-num" }, [
                  _vm._v("(" + _vm._s(_vm.data.goodList.length) + "/100)"),
                ]),
              ]
            ),
          ]),
          _c("el-divider"),
          _c("div", { staticClass: "module-title" }, [_vm._v("样式设置")]),
          _c("div", { staticClass: "control-group" }, [
            _c("div", { staticClass: "control-group__header" }, [
              _vm._v("列表样式"),
            ]),
            _c("div", { staticClass: "control-group__content" }, [
              _c(
                "div",
                { staticClass: "list-radio x-bc" },
                _vm._l(_vm.listStyle, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "item-radio cr",
                      class: { active: _vm.data.curListStyleIndex === index },
                      on: {
                        click: function ($event) {
                          return _vm.clickListStyle(index)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [
                          _c("iconpark-icon", {
                            attrs: { name: item.icon, size: "22px" },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(item.text)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "control-group" }, [
            _c("div", { staticClass: "control-group__header" }, [
              _vm._v("商品样式"),
            ]),
            _c("div", { staticClass: "control-group__content" }, [
              _c(
                "div",
                { staticClass: "list-goods x-w" },
                _vm._l(_vm.goodsStyle, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item,
                      staticClass: "item-goods cr",
                      class: { active: _vm.data.curGoodsStyleIndex === index },
                      on: {
                        click: function ($event) {
                          return _vm.clickGoodsStyle(index)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c("div", { staticClass: "control-group x-bc" }, [
            _c("div", { staticClass: "control-group__header" }, [
              _vm._v("购买按钮样式"),
            ]),
            _c("div", { staticClass: "control-group__content" }, [
              _c(
                "div",
                {
                  staticClass: "shoppingCart x-bc cr",
                  on: { click: _vm.selectShoppingCart },
                },
                [
                  _c(
                    "div",
                    { staticClass: "left x-f" },
                    [
                      _c("iconpark-icon", {
                        staticClass: "shoppingIcon",
                        attrs: {
                          name: _vm.shoppingCart[_vm.data.curShoppingCardIndex]
                            .icon,
                          color:
                            _vm.shoppingCart[_vm.data.curShoppingCardIndex]
                              .color || "#fe5a34",
                          size: "24px",
                        },
                      }),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.shoppingCart[_vm.data.curShoppingCardIndex]
                                .text
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("iconpark-icon", { attrs: { name: "down" } }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.data.selectShoppingCart,
                  expression: "data.selectShoppingCart",
                },
              ],
              staticClass: "select-shoppingCart",
            },
            _vm._l(_vm.shoppingCart, function (item, index) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "shoppingCartItem",
                  on: {
                    click: function ($event) {
                      return _vm.pitchShoppingCart(index)
                    },
                  },
                },
                [
                  _c("iconpark-icon", {
                    staticClass: "shoppingCartIcon",
                    attrs: {
                      name: item.icon,
                      color: item.color || "#fe5a34",
                      size: "24px",
                    },
                  }),
                  _vm._v(" " + _vm._s(item.text) + " "),
                ],
                1
              )
            }),
            0
          ),
          _vm.isDiyBtnText
            ? _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("按钮文字"),
                ]),
                _c(
                  "div",
                  { staticClass: "control-group__content" },
                  [
                    _c("el-input", {
                      staticStyle: { width: "160px" },
                      model: {
                        value: _vm.data.btnText,
                        callback: function ($$v) {
                          _vm.$set(_vm.data, "btnText", $$v)
                        },
                        expression: "data.btnText",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.moreSetArea,
                  expression: "moreSetArea",
                },
              ],
            },
            [
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("商品名称"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group x-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: { active: _vm.data.moreSet.isShowName },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("isShowName", true)
                          },
                        },
                      },
                      [_vm._v(" 显示 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: { active: !_vm.data.moreSet.isShowName },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("isShowName", false)
                          },
                        },
                      },
                      [_vm._v(" 不显示 ")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("商品描述"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group x-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: { active: _vm.data.moreSet.isShowDescribe },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("isShowDescribe", true)
                          },
                        },
                      },
                      [_vm._v(" 显示 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: { active: !_vm.data.moreSet.isShowDescribe },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("isShowDescribe", false)
                          },
                        },
                      },
                      [_vm._v(" 不显示 ")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("商品价格"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group x-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: { active: _vm.data.moreSet.isShowSalePrice },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("isShowSalePrice", true)
                          },
                        },
                      },
                      [_vm._v(" 显示 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: { active: !_vm.data.moreSet.isShowSalePrice },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick(
                              "isShowSalePrice",
                              false
                            )
                          },
                        },
                      },
                      [_vm._v(" 不显示 ")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("商品原价"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group x-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: { active: _vm.data.moreSet.isShowOriginalPrice },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick(
                              "isShowOriginalPrice",
                              true
                            )
                          },
                        },
                      },
                      [_vm._v(" 显示 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: !_vm.data.moreSet.isShowOriginalPrice,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick(
                              "isShowOriginalPrice",
                              false
                            )
                          },
                        },
                      },
                      [_vm._v(" 不显示 ")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("售罄商品置底显示"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group x-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: { active: _vm.data.moreSet.isSellOutBottom },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("isSellOutBottom", true)
                          },
                        },
                      },
                      [_vm._v(" 置底 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: { active: !_vm.data.moreSet.isSellOutBottom },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick(
                              "isSellOutBottom",
                              false
                            )
                          },
                        },
                      },
                      [_vm._v(" 不置底 ")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("商品倒角"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group x-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active:
                            _vm.data.moreSet.goodsBorderAngle === "rightAngle",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick(
                              "goodsBorderAngle",
                              "rightAngle"
                            )
                          },
                        },
                      },
                      [_vm._v(" 直角 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active:
                            _vm.data.moreSet.goodsBorderAngle ===
                            "filletedCorner",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick(
                              "goodsBorderAngle",
                              "filletedCorner"
                            )
                          },
                        },
                      },
                      [_vm._v(" 圆角 ")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("商品比例"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group x-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: _vm.data.moreSet.goodsRatio === "3:2",
                          noDrop: _vm.data.curListStyleIndex == 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("goodsRatio", "3:2")
                          },
                        },
                      },
                      [_vm._v(" 3:2 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: _vm.data.moreSet.goodsRatio === "1:1",
                          noDrop: _vm.data.curListStyleIndex == 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("goodsRatio", "1:1")
                          },
                        },
                      },
                      [_vm._v(" 1:1 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: _vm.data.moreSet.goodsRatio === "3:4",
                          noDrop: _vm.data.curListStyleIndex == 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("goodsRatio", "3:4")
                          },
                        },
                      },
                      [_vm._v(" 3:4 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: _vm.data.moreSet.goodsRatio === "16:9",
                          noDrop: _vm.data.curListStyleIndex == 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("goodsRatio", "16:9")
                          },
                        },
                      },
                      [_vm._v(" 16:9 ")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("图片填充"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group x-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: { active: _vm.data.moreSet.isPicFill },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("isPicFill", true)
                          },
                        },
                      },
                      [_vm._v(" 填充 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: { active: !_vm.data.moreSet.isPicFill },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("isPicFill", false)
                          },
                        },
                      },
                      [_vm._v(" 周边留白 ")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("文本样式"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group x-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: _vm.data.moreSet.textStyle === "常规",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("textStyle", "常规")
                          },
                        },
                      },
                      [_vm._v(" 常规 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: _vm.data.moreSet.textStyle === "加粗",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("textStyle", "加粗")
                          },
                        },
                      },
                      [_vm._v(" 加粗 ")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("文本对齐"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group x-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: _vm.data.moreSet.textAlignment === "left",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("textAlignment", "left")
                          },
                        },
                      },
                      [_vm._v(" 左对齐 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: _vm.data.moreSet.textAlignment === "center",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick(
                              "textAlignment",
                              "center"
                            )
                          },
                        },
                      },
                      [_vm._v(" 居中对齐 ")]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("页面边距"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c(
                    "div",
                    { staticClass: "button-group" },
                    [
                      _c("el-slider", {
                        attrs: { "show-input": "", min: 0, max: 30 },
                        model: {
                          value: _vm.data.moreSet.pageBackGauge,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.moreSet, "pageBackGauge", $$v)
                          },
                          expression: "data.moreSet.pageBackGauge",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("商品间距"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group" }, [
                    _c(
                      "div",
                      { staticClass: "block" },
                      [
                        _c("el-slider", {
                          attrs: { "show-input": "", min: 0, max: 30 },
                          model: {
                            value: _vm.data.moreSet.goodsBackGauge,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.moreSet, "goodsBackGauge", $$v)
                            },
                            expression: "data.moreSet.goodsBackGauge",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "control-group x-bc" }, [
                _c("div", { staticClass: "control-group__header" }, [
                  _vm._v("商品角标"),
                ]),
                _c("div", { staticClass: "control-group__content" }, [
                  _c("div", { staticClass: "button-group x-x" }, [
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: _vm.data.moreSet.goodsCornerMark === 0,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("goodsCornerMark", 0)
                          },
                        },
                      },
                      [_vm._v(" 不显示 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: _vm.data.moreSet.goodsCornerMark === 1,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("goodsCornerMark", 1)
                          },
                        },
                      },
                      [_vm._v(" 营销角标 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-item simpleBtn cr",
                        class: {
                          active: _vm.data.moreSet.goodsCornerMark === 2,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moreSetAreaClick("goodsCornerMark", 2)
                          },
                        },
                      },
                      [
                        _vm._v(" 属性角标 "),
                        _c("el-tooltip", { attrs: { placement: "top" } }, [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [_vm._v(" 需在商品编辑页关联标签才会显示属性角标 ")]
                          ),
                          _c("i", { staticClass: "el-icon-info" }),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data.moreSet.goodsCornerMark === 1,
                      expression: "data.moreSet.goodsCornerMark === 1",
                    },
                  ],
                  staticClass: "control-group x-bc",
                },
                [
                  _c("div", { staticClass: "control-group__header" }, [
                    _vm._v("角标样式"),
                  ]),
                  _c("div", { staticClass: "control-group__content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "shoppingCart x-bc cr",
                        on: { click: _vm.selectCornerMark },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "left x-f" },
                          [
                            _c("iconpark-icon", {
                              staticClass: "shoppingIcon",
                              attrs: {
                                name: _vm.cornerMark[
                                  _vm.data.moreSet.curCornerMarkIndex
                                ].icon,
                                color:
                                  _vm.cornerMark[
                                    _vm.data.moreSet.curCornerMarkIndex
                                  ].color || "#fe5a34",
                                size: "24px",
                              },
                            }),
                            _c("div", { staticClass: "text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.cornerMark[
                                      _vm.data.moreSet.curCornerMarkIndex
                                    ].text
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c("iconpark-icon", { attrs: { name: "down" } }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data.moreSet.selectCornerMark,
                      expression: "data.moreSet.selectCornerMark",
                    },
                  ],
                  staticClass: "select-cornerMark",
                },
                _vm._l(_vm.cornerMark, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "cornerMarkItem",
                      on: {
                        click: function ($event) {
                          return _vm.pitchCornerMark(index)
                        },
                      },
                    },
                    [
                      _c("iconpark-icon", {
                        staticClass: "cornerMarkIcon",
                        attrs: {
                          name: item.icon,
                          color: item.color || "#fe5a34",
                          size: "24px",
                        },
                      }),
                      _vm._v(" " + _vm._s(item.text) + " "),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.data.moreSet.curCornerMarkIndex === 4,
                      expression: "data.moreSet.curCornerMarkIndex === 4",
                    },
                  ],
                  staticClass: "diyCornerMark",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "imgBox marR10",
                      on: {
                        click: function ($event) {
                          _vm.materialOpen = true
                        },
                      },
                    },
                    [
                      !_vm.data.moreSet.cornerMarkUrl
                        ? _c("div", { staticClass: "y-c" }, [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _c("span", [_vm._v("添加图片")]),
                          ])
                        : _c("el-image", {
                            attrs: {
                              src: _vm.data.moreSet.cornerMarkUrl,
                              width: "100px",
                              height: "100px",
                              alt: "#",
                            },
                          }),
                      _c("div", { staticClass: "tag" }, [_vm._v("更换图片")]),
                    ],
                    1
                  ),
                  !_vm.data.moreSet.cornerMarkUrl
                    ? _c("div", { staticClass: "tag" }, [
                        _vm._v("请上传角标图片"),
                      ])
                    : _vm._e(),
                  _c("div", [_vm._v("推荐使用100X100像素的.png图片")]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "moreSet more", on: { click: _vm.clickMoreSet } },
            [
              _vm._v(" 更多设置 "),
              _c("iconpark-icon", { attrs: { name: _vm.moreSetIcon } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "素材库",
            visible: _vm.materialOpen,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.materialOpen = $event
            },
          },
        },
        [
          _c("Material", {
            attrs: { isDialog: true, isRadio: true },
            on: { accessUrlListCom: _vm.geCornerMarktUrl },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.imgUpdateSubmit },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectGoods", {
        attrs: { isShopp: true, OpenGoods: _vm.showGoodsDia },
        on: {
          "update:OpenGoods": function ($event) {
            _vm.showGoodsDia = $event
          },
          "update:open-goods": function ($event) {
            _vm.showGoodsDia = $event
          },
          getGoodsIds: _vm.pitchGoods,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }